import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../../components/layout";

const PythonDeveloper = ({ location: { pathname }, pageContext }) => {
  const title = "Python developer z znajomością Django";

  return (
    <Layout
      seo={{
        title: title,
        href: pathname,
        lang: "pl",
      }}
    >
      <Helmet>
        <script
          async
          src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
        ></script>
      </Helmet>
      <section className="page_recrutation">
        <h1>Kariera</h1>
        <div className="row">
          <div className="col-md-3">
            <ul className="categories">
              <li className="cat-item ">
                <Link to="/kariera/account-specialist">Account Specialist</Link>
              </li>
              {/* <li className="cat-item">
                <Link to="/kariera/strategy-planner">Strategy Planner</Link>
              </li> */}
              <li className="cat-item">
                <Link to="/kariera/web-designer">Web Designer</Link>
              </li>
              <li className="cat-item">
                <Link to="/kariera/react-developer">React.js Developer</Link>
              </li>
              <li className="cat-item">
                <Link to="/kariera/php-developer">PHP Developer</Link>
              </li>
              <li className="cat-item active">
                <Link to="/kariera/python-developer">
                  Python & Django developer
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-8 offset-md-1">
            <div className="panel-description">
              <p>
                Miejsce pracy:{" "}
                <strong>
                  Preferowane stacjonarnie w Krakowie opcjonalnie zdalnie cała
                  Polska
                </strong>
              </p>
              <p>
                ADream to grupa spółek (naszych własnych) z kluczowych obszarów
                marketingu. Tworzymy nowe brandy i wprowadzamy je na rynek,
                odświeżamy i rozwijamy również te już istniejące. Nasze główne
                obszary działania to; strategia, identyfikacja wizualna,
                opakowania i etykiety, serwisy i aplikacje internetowe, serwisy
                ecommerce, aplikacje webowe, kampanie wizerunkowe i sprzedażowe,
                social media - to tak w skrócie:) Współpracujemy z Klientami z
                Polski i z zagranicy.
              </p>
              <h2>Opis stanowiska:</h2>
              <p>
                Z chęcią przywitamy w teamie osobę o szerokich horyzontach w
                zakresie kodowania w Phytonie w oparciu o framework Django.
                Szukamy poukładanych ludzi, którzy potrafią robić dobrą robotę
                przy odpowiedzialnych projektach, które mają skilla i potrafią
                go wykorzystać najlepiej jak potrafią.
              </p>
              <h2>W ADream będziesz mieć możliwość:</h2>
              <ul>
                <li>Tworzenia produktu klasy ERP zaczynając od modułu CRM</li>
                <li>Pracować przy produkcie w modelu SaaS</li>
                <li>Tworzyć portale internetowe</li>
                <li>Pracować pracy przy projektach różnej wielkości</li>
                <li>
                  Projektować architektury systemów zgodnie z ustalonymi
                  najlepszymi praktykami branżowymi oraz zgodnie standardami
                  firmy
                </li>
                <li>Rozwoju zawodowego</li>
                <li>
                  Pracować z szerokim spektrum nowoczesnych technologii i
                  rozwiązań informatycznych
                </li>
                <li>Nigdy się nie nudzić :)</li>
              </ul>
              <h2>Oczekujemy:</h2>
              <ul>
                <li>Dobra a nawet bardzo dobra znajomość Pythona</li>
                <li>Bardzo dobra znajomość Django</li>
                <li>
                  Dodatkowym atutem będzie znajomość innych języków
                  programowania w R lub C ++
                </li>
                <li>Znajomość JavaScript, HTML, CSS</li>
                <li>
                  Mile widziane doświadczenie tworzenia aplikacji frontendowych
                  JavaScript / TypeScript przy użyciu popularnych frameworków,
                  takich jak Vue.js, React
                </li>
                <li>Doświadczenie w pracy z dużymi zbiorami danych</li>
                <li>
                  Doświadczenie w integracji różnych małych i dużych platform z
                  wykorzystaniem istniejących lub wymagających tworzenia nowych,
                  modułowych interfejsów API
                </li>
                <li>Doświadczenie w środowisku chmurowym. Preferowany AWS</li>
                <li>Mocne umiejętności komunikacyjne</li>
                <li>
                  Co najmniej 3 lata odpowiedniego doświadczenia w projektowaniu
                  i wdrażaniu aplikacji
                </li>
                <li>
                  Silne poczucie odpowiedzialności za obszar projektowy i chęć
                  dostarczania wysokiej jakości kodu
                </li>
                <li>
                  Zdolność do pracy nad wieloma priorytetami w szybko
                  zmieniającym się środowisku
                </li>
                <li>
                  Umiejętność pracy zespołowej w zespołach z różnych obszarów
                  firmy
                </li>
                <li>Bardzo dobrej znajomości GITa</li>
              </ul>
              <h2>Oferujemy:</h2>
              <ul>
                <li>pracę w klimatyzowanym biurze w centrum Krakowa</li>
                <li>
                  różnorodność projektów (od średnich po duże, od kreatywnych po
                  te uczące cierpliwości ;)
                </li>
                <li>formę współpracy dopasowaną do potrzeb (umowa/b2b)</li>
                <li>
                  obowiązkową przerwę regeneracyjną (będziesz pracował 7 godzin
                  i 15 minut a 45 minut poświęcisz na przerwę; wyjście na taras,
                  do pobliskiego parku czy na obiad)
                </li>
                <li>kawę z ekspresu bez limitu</li>
                <li>świeże owoce</li>
                <li>coroczne wyjazdy integracyjne</li>
                <li>fajny team!</li>
                <li>
                  Płaską strukturę w organizacji w 9 letniej firmie budowanej od
                  zera
                </li>
                <li>Niekorporacyjną atmosferę, małe zespoły</li>
                <li>
                  Możliwa praca w niepełnym wymiarze godzin, w układzie
                  dostosowanym do zajęć
                </li>
              </ul>
              <h2>Wymagane informacje i dokumenty:</h2>
              <p>
                Najbardziej interesują nas Twoje chęci i umiejętności dlatego
                wystarczy jak prześlesz <strong>jedynie portfolio</strong> wraz
                z informacją w jakim obszarze możesz projektować oraz w którym z
                nich czujesz się najlepiej - resztę omówimy na spotkaniu:)
              </p>
              <iframe
                id="python-developer-form"
                title="Recrutation form - Python"
                class="clickup-embed clickup-dynamic-height"
                src="https://forms.clickup.com/4615748/f/4cvj4-5628/42MABPGEK4QA5GHQGL"
                onwheel=""
                width="100%"
                height="100%"
                style={{ border: "none" }}
              ></iframe>
              <p className="page_recrutation_signature">
                Administratorem Danych Osobowych jest Adream sp. z o.o. z
                siedzibą w Krakowie, ul. Chopina 18/3a, KRS: 0000517132. Podanie
                danych osobowych w CV jest dobrowolne, lecz konieczne do
                przeprowadzenia procesu rekrutacyjnego. Konsekwencją niepodania
                danych osobowych będzie brak możliwości przeprowadzenia
                postępowania rekrutacyjnego. Dane osobowe przetwarzane będą na
                podstawie art. 6 ust. 1 pkt. a i c ogólnego rozporządzenia o
                ochronie danych osobowych z 27 kwietnia 2016 r. (RODO).
                Przysługuje Pani/ Panu prawo dostępu do treści swoich danych
                osobowych oraz prawo ich sprostowania, usunięcia, ograniczenia
                przetwarzania, prawo do przenoszenia danych, prawo do wniesienia
                sprzeciwu, prawo do cofnięcia zgody na ich przetwarzanie w
                dowolnym momencie bez wpływu na zgodność z prawem przetwarzania,
                którego dokonano na podstawie zgody przed jej cofnięciem.
                Przysługuje Pani/Panu prawo wniesienia skargi na przetwarzanie
                danych do Prezesa Urzędu Ochrony Danych Osobowych. Dane osobowe
                będą przetwarzane do momentu zakończenia prowadzenia rekrutacji,
                nie dłużej jednak niż przez trzy miesiące od dnia przesłania CV.
                Za zgodą kandydatów w okresie 1 roku od zakończenia procesu
                rekrutacyjnego dane osobowe będą przetwarzane w kolejnych
                rekrutacjach prowadzonych przez administratora danych osobowych.
                Dane osobowe kandydatów nie będą podlegały zautomatyzowanemu
                przetwarzaniu.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default PythonDeveloper;
